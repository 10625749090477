<template>
  <div v-if="props.flowImgList.length == 0 && shownodate" class="nodata">
    <el-empty image="/img/nodata.png" description="抱歉，未找到你想要的图片"/>
  </div>
  <div v-else>
    <FullLoading v-if="isFullLoading" />
    <div v-else>
      <div class="waterfall-grid">
        <div
            v-for="(item, index) in props.flowImgList"
            :key="item.id"
            class="waterfall-item"
            :style="{
            flexBasis: `${(item.width / item.height) * 200}px`, // 根据宽高比动态计算宽度
            flexGrow: (item.width / item.height), // 根据宽高比调整 flexGrow
            maxWidth: '500px'
          }"
        >
          <NuxtLink
              :title="item.title"
              :to="'/detailsMaterialCenter/id/'+item.id+'/type/'+props.selectId"
              class="image-link"
          >
            <NuxtImg
                :src="item.thumb"
                :alt="item.title"
                :key="item.thumb"
                class="waterfall-img"
                :style="{
                objectFit: parseInt(props.selectId) == 1 ? 'cover' : 'scale-down'
              }"
                :placeholder="zwimg"
                loading="lazy"

            />
          </NuxtLink>
          <div class="vip-container" v-if="item.tem_member > 0">
            <i class="iconfont icon-vip3 tubiao"></i>
          </div>
        </div>
      </div>
      <div class="paging" v-if="showpage">
        <el-pagination
            @change="getQueryParams"
            :page-size="props.pagesize"
            class="paging"
            background
            :total="props.total"
            v-model:current-page="pageNumber"
            layout="prev, pager, next"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const zwimg = ref('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
const emit = defineEmits(['page'])
const props = defineProps({
  flowImgList: Object,
  total: Number,
  pagesize: Number,
  page: Number,
  selectId: Number
});

const images = ref([]);
images.value = props.flowImgList;

const isFullLoading = ref(true);
const pageNumber = ref(Number(props.page) ?? 1);
const loadedImages = ref(0);
const showpage = ref(false);
const shownodate = ref(false);

watch(() => props.flowImgList, async (newValue, oldValue) => {
  isFullLoading.value = true;
  pageNumber.value = Number(props.page);
  images.value = props.flowImgList;
  if (images.value.length == 0) {
    shownodate.value = true;
  }
  loadedImages.value = 0;

  setTimeout(() => {
    isFullLoading.value = false;
    showpage.value = true;
  }, 500);
});

const getQueryParams = (number) => {
  emit('page', number);
};

const handleImageLoad = () => {
  loadedImages.value++;
  if (loadedImages.value === images.value.length) {
    //showpage.value = true;
  }
};

onMounted(async () => {
  setTimeout(() => {
    isFullLoading.value = false;
    showpage.value = true;
  }, 500);
});
</script>

<style lang="less" scoped>
.waterfall-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding-bottom: 50px;

  &::after {
    content: '';
    flex-grow: 999999999;
    min-width: 200px;
    height: 0;
  }
}

.waterfall-item {
  margin-bottom: 3px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  background: #f5f7fd;

  &:hover {
    background: linear-gradient(180deg, rgba(27, 35, 55, .6), rgba(27, 35, 55, 0));

    .waterfall-img {
      transform: scale(1.2);
    }
  }
}

.image-link {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.waterfall-img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  display: block;
  transition: transform 0.2s ease;
  image-rendering: -webkit-optimize-contrast;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.vip-container {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
}

.paging {
  display: flex;
  justify-content: center;
  margin: 10px 0 40px;
}

.nodata {
  display: flex;
  justify-content: center;
}
</style>