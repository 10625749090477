<!--素材中心搜索页面-->
<template>
  <div id="appMain">
    <PanelHeader></PanelHeader>
    <main>
      <PanelLeft></PanelLeft>
      <div class="panel-mainContent">
        <material-serarch-details></material-serarch-details>
        <footer class="panel-footer">
          <public-footer></public-footer>
        </footer>
      </div>
    </main>
  </div>
</template>
<script lang="ts" setup>
import PanelHeader from "/layout/components/PanelHeader.vue";
import PanelLeft from "/layout/components/PanelLeft.vue";
import MaterialSerarchDetails from "./materialSerarchDetails.vue";
import { getSeoList } from '@/src/api/apiconfig/main';
import { useLoginStore } from '@/src/store/modules/login'
import PublicFooter from "~/src/components/PublicFooter/index.vue";
const loginStore = useLoginStore();
const route = useRoute();
watch(() => route.query, async(newParam) => {
  const container = document.querySelector(".panel-mainContent")
  container.scrollTo(0, 0);
})
const gid = ref(useRoute().query.t ?? 0); // 分类id
const id  = ref(useRoute().query.g ?? 0); // 类型id
const gid_name = ref('图片');
const id_name =ref('全部');
const seo_title = ref('素材中心_松鼠快图_松鼠快图官网_在线图片编辑_在线作图网站')
const seo_key = ref('素材中心,松鼠快图,松鼠快图官网,在线图片编辑,在线作图网站')
const seo_des = ref('松鼠快图素材中心提供在线设计图片的多种图片设计素材，满足在线设计图片的需求。');
if(gid == 1 ){
  gid_name.value = '图片';
}else if(gid == 2){
  gid_name.value = '素材';
}else{
  gid_name.value = '字体';
}

if(gid.value == 0 && id.value == 0){

}else{
  const seo_res  = await getSeoList({gid:gid.value,id:id.value});
  gid_name.value = seo_res.data.gid;
  id_name.value = seo_res.data.id;
  seo_title.value = id_name.value+gid_name.value+'素材中心_松鼠快图_松鼠快图官网_在线图片编辑_在线作图网站';
  seo_key.value = id_name.value+gid_name.value+'素材中心,松鼠快图,松鼠快图官网,在线图片编辑_在线作图网站';
  seo_des.value = '松鼠快图'+id_name.value+gid_name.value+'素材中心提供在线设计图片的多种图片设计素材，满足在线设计图片的需求。';
}

useHead({
  title:seo_title.value,
  meta:[
    {hid:'keywords',name:'keywords','content':seo_key.value},
    {hid:'description',name:'description','content':seo_des.value},
  ]
})

</script>
<style>
html,
body,
#app,
#__nuxt {
  height: 100%;
}
</style>
<style lang='less' scoped>
#appMain {
  display: flex;
  flex-direction: column;
  height: 100%;
  main {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    overflow: hidden;
    .panel-mainContent {
      flex: 1;
      overflow: auto;
      padding: 25px 45px 45px 45px ;
    }
  }
}
</style>