<!--模板中心-->
<template>
  <component :is="deviceComponent" />
</template>
<script setup lang="ts">
import MaterialRetrievalPc  from "@/src/components/materialCenter/materialRetrievalPc.vue";
import MaterialRetrievalWap from "@/src/components/wap/materialCenter/index.vue";
import { useDevice } from '@/src/utils/useDevice'
const { deviceComponent } = useDevice(MaterialRetrievalPc, MaterialRetrievalWap)
</script>
<style scoped>
body{

}
</style>
