<template>
  <div v-if="props.flowImgList.length == 0 && !isFullLoading" class="nodata" >
    <el-empty image="/img/nodata.png" description="抱歉，未找到你想要的图片"/>
  </div>
  <FullLoading v-show="isFullLoading" />
  <wc-waterfall :gap="8" :cols="2">
    <div
        v-for="(img, index) in props.flowImgList"
        :key="img.id"
        class="waterfall-item"
    >
    <NuxtLink :title="img.title" :to="'/detailsMaterialCenter/id/'+img.id+'/type/'+props.selectId">
      <nuxt-img
          :src="img.thumb"
          :placeholder="zwimg"
          :alt="img.title"
          loading="lazy"
          class="waterfall-img"
      />
    </NuxtLink>
    </div>
  </wc-waterfall>
<!--  <div v-show="!isFullLoading" class="waterfall-container my-waterfall" >-->
<!--    <div-->
<!--        v-for="(img, index) in props.flowImgList"-->
<!--        :key="img.id"-->
<!--        class="waterfall-item"-->
<!--        :style="getItemStyle(index)"-->
<!--    >-->
<!--      <NuxtLink :title="img.title" :to="'/detailsMaterialCenter/id/'+img.id+'/type/'+props.selectId">-->
<!--        <img-->
<!--            :src="img.coverimg"-->
<!--            :alt="img.title"-->
<!--            loading="lazy"-->
<!--            @load="(event) => { imgOnLoad(event, img, index); }"-->
<!--            class="waterfall-img"-->
<!--        />-->
<!--      </NuxtLink>-->
<!--    </div>-->
<!--  </div>-->
  <div class="paging" v-show="!isFullLoading && props.flowImgList.length > 0" style="margin-bottom: 40px;">
    <!--分页-->
    <el-pagination
        size="small"
        @change="getQueryParams"
        :page-size="props.pagesize"
        class="paging"
        defailt background
        :total="props.total"
        v-model:current-page="currentPage"
        layout="prev, pager, next ">
    </el-pagination>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch, nextTick } from 'vue';
import debounce from 'lodash/debounce';
const zwimg = ref('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
const emit = defineEmits(['page'])
const props = defineProps({
  flowImgList: Array,
  total: Number,
  pagesize: Number,
  page: [Number, String],
  selectId: [Number, String]
});
// 分页相关
const currentPage = ref(props.page || 1);
const positions = ref([]); // 存储每个图片的位置信息
const column = 2;
const gap = 3;
const loadedImages = ref(new Set()); // 跟踪已加载的图片
const isFullLoading = ref(true);
const isInitialized = ref(false);

// 监听数据变化
// watch(() => props.flowImgList, async (newVal) => {
//   if (newVal && newVal.length > 0) {
//     loadedImages.value.clear();
//     isInitialized.value = false;
//     await nextTick();
//     initLayout();
//   }else{
//     const waterfallBox = document.querySelector(".my-waterfall");
//     if (waterfallBox) {
//       waterfallBox.style.height = '0px';
//     }
//     positions.value = [];
//     loadedImages.value.clear();
//     isInitialized.value = false;
//     isFullLoading.value = false;
//   }
// }, { deep: true });

const getQueryParams = ((number)=>{
  currentPage.value = number;
  emit('page',  number)
})

// 初始化布局
const initLayout = () => {
  const waterfallBox = document.querySelector(".my-waterfall");
  if (!waterfallBox) return;

  const itemsWidth = (waterfallBox.offsetWidth - gap * (column - 1)) / column;
  positions.value = props.flowImgList.map((_, index) => ({
    left: (index % column) * (itemsWidth + gap),
    top: Math.floor(index / column) * (itemsWidth + gap),
    width: itemsWidth,
    height: itemsWidth // 初始高度设为宽度相等
  }));

  updateLayout();
};

// 计算每个项目的样式
const getItemStyle = (index) => {
  if (!positions.value[index]) return {};
  return {
    transform: `translate3d(${positions.value[index].left}px, ${positions.value[index].top}px, 0)`,
    width: `${positions.value[index].width}px`,
    height: `${positions.value[index].height}px`
  };
};

// 防抖处理的布局计算
const updateLayout = debounce(() => {
  const waterfallBox = document.querySelector(".my-waterfall");
  if (!waterfallBox || props.flowImgList.length === 0) {
    waterfallBox && (waterfallBox.style.height = '0px');
    return;
  }

  const itemsWidth = (waterfallBox.offsetWidth - gap * (column - 1)) / column;
  const columnHeights = Array(column).fill(0);
  const newPositions = [];

  props.flowImgList.forEach((img, index) => {
    let height;

    // 1. 首先尝试使用已加载图片的实际尺寸
    if (loadedImages.value.has(index)) {
      const imgElement = waterfallBox.children[index]?.querySelector('img');
      if (imgElement && imgElement.naturalWidth && imgElement.naturalHeight) {
        const aspectRatio = imgElement.naturalWidth / imgElement.naturalHeight;
        height = itemsWidth / aspectRatio;
      }
    }

    // 2. 如果没有加载完成，使用图片对象中的原始宽高比
    if (!height && img.width && img.height) {
      const aspectRatio = img.width / img.height;
      height = itemsWidth / aspectRatio;
    }

    // 3. 如果仍然没有宽高比，才使用默认正方形
    if (!height) {
      height = itemsWidth;
    }

    const columnIndex = columnHeights.indexOf(Math.min(...columnHeights));
    const left = columnIndex * (itemsWidth + gap);
    const top = columnHeights[columnIndex];

    newPositions[index] = {
      left,
      top,
      width: itemsWidth,
      height
    };

    columnHeights[columnIndex] += height + gap;
  });

  positions.value = newPositions;
  waterfallBox.style.height = `${Math.max(...columnHeights)}px`;

  // 如果所有图片都加载完成，更新初始化状态
  if (loadedImages.value.size === props.flowImgList.length) {
    isInitialized.value = true;
    isFullLoading.value = false;
  }
}, 100);

// 图片加载完成后更新实际高度
const imgOnLoad = async (event, img, index) => {
  if (!event.target) return;

  const imgElement = event.target;
  const naturalWidth = imgElement.naturalWidth;
  const naturalHeight = imgElement.naturalHeight;

  if (positions.value[index]) {
    const itemWidth = positions.value[index].width;
    const itemHeight = (itemWidth * naturalHeight) / naturalWidth;
    positions.value[index].height = itemHeight;
    loadedImages.value.add(index);

    // 只有当图片尺寸发生变化时才更新布局
    if (Math.abs(itemHeight - positions.value[index].height) > 1) {
      await nextTick();
      updateLayout();
    }
  }
};

// 监听窗口大小变化
const handleResize = debounce(() => {
  //initLayout();
}, 200);

onMounted(async () => {
  //await nextTick();
  //initLayout();
  isFullLoading.value = false;
 // window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
 // window.removeEventListener('resize', handleResize);
});
</script>

<style>
.waterfall-container {
  position: relative;
  width: 100%;
  min-height: 100px;
  transition: height 0.3s ease; /* 添加过渡效果 */
}

.waterfall-item {
  position: absolute;
  background: #f5f7fd;
  border-radius: 5px;
  overflow: hidden;
  will-change: transform;
  transition: transform 0.3s ease;
}

.waterfall-img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  vertical-align: bottom;
}
</style>