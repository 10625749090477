<template>
  <div class="main-rq">
    <div class="search-rq">
      <el-select
          v-model="selectId"
          placeholder="Select"
          size="large"
          style="width: 120px;margin-right: 5px;"
          @change="handleMaterialTypeChange"
      >
        <el-option
            v-for="item in Groupoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
      <el-input
          v-model="input"
          style="width: 100%"
          placeholder="搜索素材"
          :prefix-icon="Search"
          size="large"
          @keyup.enter="getSearch(input)"
      />
    </div>
    <el-scrollbar>
      <div class="scrollbar-flex-content">
        <p v-for="item in groupArray" :key="item" class="scrollbar-demo-item">
          <NuxtLink :class="{ selecteda: selectId2 == item.id }" :title="item.title" :to="{name:'materialCenter',query:{t:selectId,g:item.id,c:color,b:banshi,k:input,p:1,s:sort}}">
            {{ item.title }}
          </NuxtLink>
        </p>
      </div>
    </el-scrollbar>

    <div class="imgBox">
      <waterfallflow
          :flowImgList="flowImgList"
          :total="total"
          :pagesize="pagesize"
          :selectId="selectId"
          @page="getPage"
          :page="page"
      />
    </div>
  </div>
  <div class="backtop" @click="backtop"><i class="iconfont icon-shangjiantou"></i></div>
  <Footers></Footers>
</template>
<script setup lang="ts">
import Footers from '@/src/components/wap/footer/index.vue'
import {Search} from "@element-plus/icons-vue";
import {getMaterialGroup, getSearchMaterialImg, getSeoList} from "@/src/api/apiconfig/main";
import WaterfallFlowImg from "@/src/components/materialCenter/waterfallFlowImg.vue";
import waterfallflow from '@/src/components/wap/materialCenter/waterfallFlow.vue'
import {WorkSpaceDrawType} from "~/src/configs/canvas";
import {setEternalLocal} from "~/src/utils/local";
const groupArray = ref([]);
const Groupoptions = [
  {
    value: '1',
    label: '图片',
  },
  {
    value: '2',
    label: '素材',
  },
  {
    value: '3',
    label: '文字',
  },
];
const selectId = ref(useRoute().query.t ?? '1')
const selectId2 = ref(useRoute().query.g ?? 0)
const page = ref(useRoute().query.p ?? 1)
const input = ref(useRoute().query.k ?? '')
const banshi = ref(useRoute().query.b ?? 0);
const color = ref(useRoute().query.c ?? 0);
const sort = ref(useRoute().query.s ?? 0);
const banshi_value = ref('全部');
const color_value = ref(0);
const route = useRoute()
const flowImgList =ref([])
const total = ref(0);
const pagesize = ref(40);
const gid = ref(useRoute().query.t ?? 0); // 分类id
const id  = ref(useRoute().query.g ?? 0); // 类型id
const gid_name = ref('图片');
const id_name =ref('全部');
const seo_title = ref('素材中心_松鼠快图_松鼠快图官网_在线图片编辑_在线作图网站')
const seo_key = ref('素材中心,松鼠快图,松鼠快图官网,在线图片编辑,在线作图网站')
const seo_des = ref('松鼠快图素材中心提供在线设计图片的多种图片设计素材，满足在线设计图片的需求。');
if(gid.value == 0 && id.value == 0){

}else{
  const seo_res  = await getSeoList({gid:gid.value,id:id.value});
  gid_name.value = seo_res.data.gid;
  id_name.value = seo_res.data.id;
  seo_title.value = id_name.value+gid_name.value+'素材中心_松鼠快图_松鼠快图官网_在线图片编辑_在线作图网站';
  seo_key.value = id_name.value+gid_name.value+'素材中心,松鼠快图,松鼠快图官网,在线图片编辑_在线作图网站';
  seo_des.value = '松鼠快图'+id_name.value+gid_name.value+'素材中心提供在线设计图片的多种图片设计素材，满足在线设计图片的需求。';
}

useHead({
  title:seo_title.value,
  meta:[
    {hid:'keywords',name:'keywords','content':seo_key.value},
    {hid:'description',name:'description','content':seo_des.value},
  ]
})
onMounted(() => {
  //document.body.style.overflowY = 'hidden';
});

onBeforeUnmount(() => {
  //document.body.style.overflowY = ''; // 恢复默认样式
});

const backtop = () => {
  const container = document.querySelector("body")
  container.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
}

const getSearch =((row)=>{
  if(row){
    input.value = row
  }
  searchMaterialImg()
  navigateTo({ path: 'materialCenter',query:{
      t:selectId.value,
      g:selectId2.value,
      p:1,
      k:input.value,
      b:banshi.value,
      c:color.value,
      s:sort.value
    }})
})


// 处理分类选择变化
const handleMaterialTypeChange = async () => {

  navigateTo({ path: 'materialCenter',query:{
      t:selectId.value,
      g:0,
      p:1,
      k:input.value,
      b:banshi.value,
      c:color.value,
      s:sort.value
    }})

};

// 分页跳转
const getPage = (async (value)=>{
  page.value = value;
  navigateTo({ path: 'materialCenter',query:{
      t:selectId.value,
      g:selectId2.value,
      p:value,
      k:input.value,
      b:banshi.value,
      c:color.value,
      s:sort.value
    }})
})

//类型列表获取(接口)
const materialGroup = (async (type,pages)=>{
  const response = await getMaterialGroup({type:type})
  if (response.data.length!==0){
    groupArray.value = response.data
    await searchMaterialImg()
  }
  page.value = parseInt(pages);
})

//图片列表(接口)
const searchMaterialImg = (async ()=>{
  const  response = await getSearchMaterialImg({
    type:selectId.value,
    search:input.value?input.value:route.params.info,
    group_id:selectId2.value,
    page:page.value,
    color:color.value,
    banshi:banshi.value,
    sort:sort.value
  })
  if(response.data.list.length!==0){
    response.data.list.forEach((eitem,eindex)=> {
      //eitem.width=100;
      eitem.height=100 * eitem.height / eitem.width;
      eitem.width = 100;
    })
    flowImgList.value = response.data.list
  }else{
    flowImgList.value = []
  }
  pagesize.value = response.data.pagesize
  total.value =  response.data.total
})

watch(() => route.query, async(newParam) => {
  const container = document.querySelector("body")
  container.scrollTo(0, 0);
})

// 监听当前路由
watch(
    () => route.query,
    (newValue: any,oldPath) => {
      if(oldPath == undefined){
        return;
      }

      newValue.g == undefined ? selectId2.value = 0 : selectId2.value = newValue.g
      newValue.t == undefined ? selectId.value = '1' : selectId.value = newValue.t
      newValue.p == undefined ? page.value = 1 : page.value = newValue.p
      newValue.k == undefined ? input.value = '' : input.value = newValue.k
      newValue.b == undefined ? banshi.value = 0 : banshi.value = newValue.b
      newValue.c == undefined ? color.value = 0 : color.value = newValue.c
      newValue.s == undefined ? sort.value = 0 : sort.value = newValue.s
      if(banshi.value == 0){
        banshi_value.value = '全部';
      }else if(banshi.value == 2){
        banshi_value.value = '横板';
      }else if(banshi.value == 1){
        banshi_value.value = '竖板';
      }else if(banshi.value == 3){
        banshi_value.value = '方形';
      }
      materialGroup(selectId.value,page.value);
    },
    { immediate: true }
)

await materialGroup(selectId.value,page.value);
</script>
<style scoped>
:deep(body){
  overflow-y:hidden !important;
}
.main-rq{
  padding:10px 5px;
}
.search-rq{
  display: flex;
  justify-content: center;
}
.scrollbar-flex-content {
  display: flex;
}
.scrollbar-demo-item {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin: 10px;
  text-align: center;
  border-radius: 4px;
  color: #333;
  position: relative;
}
.selecteda::after{
  content: ' ';
  display: block;
  width: 20px;
  height: 3px;
  background-color: aqua;
  position: absolute;
  left: 50%; /* 将左边距设置为50% */
  bottom: 0;
  border-radius: 2px;
  transform: translateX(-50%); /* 向左移动自身宽度的一半以居中 */
}
:deep(.el-scrollbar__bar){
  display: none;
}
.backtop{
  position: fixed;
  bottom: 150px;
  right: 20px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: #c1c1c1;
  border-radius: 5px;
  i{
    font-size: 20px;
    font-weight: bold;
    color:#fff;
  }
}
</style>