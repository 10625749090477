<!--素材中心搜索详情组件-->
<template>
  <div ref="mianRef">
    <div class="input-rq">
      <div class="input-rq-son">
        <el-input
          v-model="input"
          style="width: 530px;"
          size="large"
          class="search-material"
          :prefix-icon="Search"
          @focus="openHistory"
          @blur="closeHistory"
          @input="showclearable"
          @keyup.enter="getSearch(input)"
          placeholder="搜索你想要的素材">
          <template #suffix>
            <i v-show="clearableState" @click="clearableinput" class="clearable iconfont icon-yuanquancuowu"></i>
            <el-button @click="getSearch(input)" class="inputBox-btn">搜索</el-button>
          </template>
        </el-input>
      </div>
      <div v-show="searchShow" class="searchList">
        <div v-show="historyList.length!=0" class="history">
          <div class="history-son-rq"><i class="iconfont icon-lishijilu"></i> <span>历史记录</span></div><div><div @click="getEmpty" class="emptyhistory">清空</div></div>
        </div>
        <div v-if="historyList.length!=0" class="list" >
          <div @click="getSearch(item)" v-for="item in historyList"><span>{{item}}</span></div>
        </div>
        <div class="searchList-title"><span><i style="color: red" class="iconfont icon-huo2"></i> 热门搜索</span></div>
        <div class="searchList-content-rq">
          <div @click="getSearch(item.title)" class="searchList-content" v-for="(item, i) in hotsearchData">
            <div class="searchList-content-num">{{i + 1}}</div><div class="searchList-content-lable">{{item.title}}</div>
          </div>
        </div>
      </div>
      <img src="/img/banner.jpg">
    </div>


    <div class="menu">
      <div class="flex-title"><span>分类：</span></div>
      <div class="flex-content">
        <div @click="getMenu(item.id,index)" v-for="(item,index) in classificationList" :class="selectId==item.id?'selectColor':''" class="content">
          <span>
            {{item.title}}
          </span>
        </div>
      </div>
    </div>
    <div class="menu">
      <div class="flex-title"><span>类型：</span></div>
      <div class="flex-content" :class="isCollapsed?'collapsed':''">
        <div ref="myDiv" class="content" :class="selectId2==item.id?'selectColor':''" v-for="(item,index) in menuList">
          <span>
             <NuxtLink :to="{name:'materialCenter',query:{t:selectId,g:item.id,c:color,b:banshi,k:input,p:1,s:sort}}">{{item.title}}</NuxtLink>
          </span>
        </div>
        </div>
      <div v-if="widthData > 1485" class="flex-item" @click="getFold">
          <span v-if="isCollapsed?true:false" >
            更多<i class="iconfont icon-zhankaishouqi-copy"></i> </span>
           <span v-else>收起 <i class="iconfont icon-zhankaishouqishang-copy"></i> </span>
        </div>
    </div>
    <div class="group-line"></div>
    <div class="more-group">
      <div class="more-group-l" >
        <div @click="handlesort(0)" :style="{fontWeight:sort==0 ? 'bold':''}">最新图片</div>
        <div @click="handlesort(1)" :style="{fontWeight:sort==1 ? 'bold':''}">热门图片</div>
      </div>
      <div class="more-group-r">
        <div class="common">
          <div class="more-group-lable">颜色：</div>
          <el-dropdown trigger="click" placement="bottom-end" ref="dropdown_color" class="dropdown_color">
              <span class="el-dropdown-link">
                <span class="common-selectted" style="display: flex">
                  <div class="common-selectted-name" v-if="color == Number(0)" style="background-image: url(/img/allcolor.png);background-size:100% 100%;width: 20px;height: 20px;border-radius: 20px;"></div>
                  <div class="common-selectted-name" v-else><div :style="{background:color_value}" style="width: 20px;height: 20px;border-radius: 20px;"></div></div>
                  <span class="common-selectted-icon"><i class="iconfont icon-xiajiantou2"></i></span>
                </span>
              </span>
              <template #dropdown>
                <el-dropdown-menu class="my-color-select">
                  <li class="my-color-select-li" @click="handlecolor(0)"><div style="background-image: url(/img/allcolor.png);background-size:100% 100%;width: 50px;height: 30px;"></div></li>
                  <li class="my-color-select-li" @click="handlecolor(item.id)" v-for="(item,index) in color_list"><div :style="{background:item.icon}" style="width: 50px;height: 30px;border: 1px solid rgba(0,0,0,.1);border-radius: 2px;"></div></li>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
        </div>
        <div class="common">
          <div class="more-group-labl">版式：</div>
          <el-dropdown trigger="click" placement="bottom-end">
            <span class="el-dropdown-link">
              <span class="common-selectted">
                <span class="common-selectted-name">{{banshi_value}}</span>
                <span class="common-selectted-icon"><i class="iconfont icon-xiajiantou2"></i></span>
              </span>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="handlebanshi(0)">全部</el-dropdown-item>
                <el-dropdown-item @click="handlebanshi(2)">横板</el-dropdown-item>
                <el-dropdown-item @click="handlebanshi(1)">竖版</el-dropdown-item>
                <el-dropdown-item @click="handlebanshi(3)">方形</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="imgBox">
      <FullLoading v-if="isFullLoading" />
      <waterfall-flow-img v-else @page="getPage" :flowImgList="flowImgList" :total="total" :pagesize="pagesize" :page="page" v-model:current-page="pageNumber":selectId="selectId" ></waterfall-flow-img>
    </div>
  </div>
</template>

<script lang="ts" setup>
import WaterfallFlowImg from "./waterfallFlowImg.vue";
import {getMaterialGroup, getSearchMaterialImg ,getcolor,getHotSearch } from "@/src/api/apiconfig/main";
import { getLocal,setEternalLocal,removeLocal } from "@/src/utils/local";
import { Search } from '@element-plus/icons-vue'
import { useLoginStore } from '@/src/store/modules/login'
const loginStore = useLoginStore();
const mianRef = ref<HTMLElement | null>(null)
const route = useRoute()
const router = useRouter()
const myDiv = ref(null);
const widthData = ref(0);

const nuxtApp = useNuxtApp()

// 是否首次加载
const isFullLoading = ref(true)

nuxtApp.hook('page:start', () => {
  isFullLoading.value = true
})

nuxtApp.hook('page:finish', () => {
  isFullLoading.value = false
})


const classificationList = ref([
  {id:1,title:'图片'},
  {id:2,title:'素材'},
  {id:3,title:'文字'},
])

const isCollapsed = ref(true)
const classificationId = ref(1)

// 搜索框使用
const searchShow = ref(false)
const historyList = ref(getLocal('historyList') ?? [])
const hotsearchData = ref([]);
const clearableState = ref(false)

// 参数赋值
const selectId = ref(useRoute().query.t ?? 1)
const selectId2 = ref(useRoute().query.g ?? 0)
const page = ref(useRoute().query.p ?? 1)
const input = ref(useRoute().query.k ?? '')
const banshi = ref(useRoute().query.b ?? 0);
const color = ref(useRoute().query.c ?? 0);
const sort = ref(useRoute().query.s ?? 0);

const menuId = ref(-1)
const content = ref(null);
const menuList = ref([])
const flowImgList =ref([])
const typeId = ref()
const pagesize = ref(40);
const total = ref(0);
const color_list = ref([]);
const banshi_value = ref('全部');
const color_value = ref(0);
const dropdown_color = ref<DropdownInstance>()
//类型列表获取(接口)
const materialGroup = (async (type)=>{

  console.log(page.value,'page')

  const response = await getMaterialGroup({type:type})
  response.data.forEach((item, index) => {
    if(selectId2.value == item.id){
      loginStore.template_g_name = item.title
    }
  })
  if (response.data.length!==0){
    menuList.value = response.data
    //menuList.value.unshift({'id':0,'title':'全部'})
    await searchMaterialImg()
  }
})
//图片列表(接口)
const searchMaterialImg = (async ()=>{
  const  response = await getSearchMaterialImg({
    type:selectId.value,
    search:input.value?input.value:route.params.info,
    group_id:selectId2.value,
    page:page.value,
    color:color.value,
    banshi:banshi.value,
    sort:sort.value
  })
  if(response.data.list.length!==0){
    flowImgList.value = response.data.list
  }else{
    flowImgList.value = []
  }
  pagesize.value = response.data.pagesize
  total.value =  response.data.total
  // 获取筛选列表
  const result  = await getcolor();
  color_list.value = result.data
})

// 监听当前路由
watch(
   () => route.query,
  (newValue: any,oldPath) => {
    if(oldPath == undefined){
      return;
    }

    newValue.g == undefined ? selectId2.value = 0 : selectId2.value = newValue.g
    newValue.t == undefined ? selectId.value = 1 : selectId.value = newValue.t
    newValue.p == undefined ? page.value = 1 : page.value = newValue.p
    newValue.k == undefined ? input.value = '' : input.value = newValue.k
    newValue.b == undefined ? banshi.value = 0 : banshi.value = newValue.b
    newValue.c == undefined ? color.value = 0 : color.value = newValue.c
    newValue.s == undefined ? sort.value = 0 : sort.value = newValue.s
    if(banshi.value == 0){
      banshi_value.value = '全部';
    }else if(banshi.value == 2){
      banshi_value.value = '横板';
    }else if(banshi.value == 1){
      banshi_value.value = '竖板';
    }else if(banshi.value == 3){
      banshi_value.value = '方形';
    }
    if(Number(color.value) === Number(0)){
      color_value.value = 0;
    }else{
      color_list.value.forEach((item,key) => {
        if(item.id == Number(color.value)){
          color_value.value = item.icon;
        }
      });
    }
    materialGroup(selectId.value);

  },
  { immediate: true }
)
await materialGroup(selectId.value);
onMounted( async () => {
  if(banshi.value == 0){
    banshi_value.value = '全部';
  }else if(banshi.value == 2){
    banshi_value.value = '横板';
  }else if(banshi.value == 1){
    banshi_value.value = '竖板';
  }else if(banshi.value == 3){
    banshi_value.value = '方形';
  }
  if(Number(color.value) === Number(0)){
    color_value.value = 0;
  }else{
    color_list.value.forEach((item,key) => {
      if(item.id == Number(color.value)){
        color_value.value = item.icon;
      }
    });
  }

  // 防止颜色赋值问题
  if(color.value > 0 && color_value.value == 0){
    const result  = await getcolor();
    color_list.value = result.data
    color_list.value.forEach((item,key) => {
      if(item.id == Number(color.value)){
        color_value.value = item.icon;
      }
    });
  }
})
//分类方法
const getMenu = ((id,index)=>{

  classificationList.value.forEach((item, index) => {
    if(id == item.id){
      loginStore.template_n_name = item.title
    }
  })

  selectId.value = id
  selectId2.value = 0;
  classificationId.value = id

  materialGroup(id)
  widthData.value = 0
  myDiv.value.forEach((item)=>{
   widthData.value  = widthData.value + item.clientWidth
  })
})
//类型方法
const getType = ((id,index)=>{
  selectId2.value = index
  //page.value = useRoute().params.p
  typeId.value = useRoute().params.g

})
//搜索
const getSearch =((row)=>{
  if(row){
    input.value = row
    historyList.value.unshift(row);
    historyList.value = [...new Set(historyList.value)]
    setEternalLocal('historyList',historyList.value);
    closeHistory();
  }
  searchMaterialImg()
  navigateTo({ path: 'materialCenter',query:{
      t:selectId.value,
      g:selectId2.value,
      p:1,
      k:input.value,
      b:banshi.value,
      c:color.value,
      s:sort.value
    }})
})

// 打开历史搜索框
const openHistory = () =>{
  searchShow.value=true
  hotSearch();
  if(input.value !=''){
    clearableState.value = true;
  }
}
// 关闭历史搜索框
const closeHistory = () => {
  clearableState.value = false
  setTimeout(function(){
    searchShow.value = false;
  },150)
}
// 清空搜索内容
const clearableinput = () =>{
  clearableState.value = false;
  input.value = '';

}
// 清空历史搜索
const getEmpty = () => {
  historyList.value.splice(0);
  removeLocal('historyList');
}
const showclearable = () => {
  openHistory();
  if(input.value == ''){
    clearableState.value = false;
  }else{
    clearableState.value = true;
  }
}

// 热门搜索(接口)
const hotSearch = (async ()=>{
  const response = await getHotSearch()
  if(response.data.length!==0){
    hotsearchData.value = response.data
  }else{
    hotsearchData.value = []
  }
})

const getFold = (()=>{
    isCollapsed.value = !isCollapsed.value
})
// 分页跳转
const getPage = (async (value)=>{
  navigateTo({ path: 'materialCenter',query:{
      t:selectId.value,
      g:selectId2.value,
      p:value,
      k:input.value,
      b:banshi.value,
      c:color.value,
      s:sort.value
  }})
})
// 选择版式
const handlebanshi = (bid) =>{
  navigateTo({ path: 'materialCenter',query:{
      t:selectId.value,
      g:selectId2.value,
      p:1,
      k:input.value,
      b:bid,
      c:color.value,
      s:sort.value
    }})
}
// 选择颜色
const handlecolor = (nowcolor) =>{
  navigateTo({ path: 'materialCenter',query:{
      t:selectId.value,
      g:selectId2.value,
      p:1,
      k:input.value,
      b:banshi.value,
      c:nowcolor,
      s:sort.value
    }})
  dropdown_color.value.handleClose()
}

// 选择排序
const handlesort = (sorts) =>{
  navigateTo({ path: 'materialCenter',query:{
      t:selectId.value,
      g:selectId2.value,
      p:page.value,
      k:input.value,
      b:banshi.value,
      c:color.value,
      s:sorts
    }})
  dropdown_color.value.handleClose()
}

</script>

<style lang="less" scoped>
.imgBox{
  width: 100%;
  margin-top: 20px;
}

.menu{
  user-select: none;
  display: flex;
  justify-content: space-between;
  .flex-title{
    width: 44px;
    height: 32px;
    display: flex;
    align-items: center;
    color: #8693ab;
    margin-right: 0px;
    font-size: 14px;
  }
  .flex-content{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    .selectColor{
      background: #f1f3f9;
    }
    .content:hover{
      background: #f1f3f9;
    }
    .content{
      height: 32px;
      border-radius: 6px;
      margin-right: 5px;
      margin-bottom: 12px;
      cursor: pointer;
      span{
        padding: 0 8px;
        line-height: 32px;
        color: #666;
        font-size: 14px;
      }
    }
  }
  .flex-item{
    width: 60px;
    height: 32px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    i{
      font-size:12px;
      margin-left: 3px;
    }
  }
  .collapsed {
    //max-height: 32px;
  }
}
.group-line{
  width: 100%;
  height: 1px;
  background: #ebeef5;
  margin: 20px 0;
}
</style>
<style lang="less" scoped>
:deep(.search-material .el-input__wrapper){
  border-radius: 20px;
}
:deep(.search-material .el-button){
 border-radius: 20px;
}
:deep(.el-dropdown-link:focus-visible){
  outline:unset;
}
:deep(.el-input__suffix){
  position: absolute;
  right: 5px;
}
.more-group{
  display: flex;
  justify-content: space-between;
}
.more-group-l{
  display: flex;
  div{
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
  }
}
.more-group-r{
  display: flex;
  .more-group-labl{
    margin-right: 10px;
    font-size: 14px;
  }
}
.common{
  display: flex;
  align-items: center;
}
.common-selectted{
  background: #eee;
  padding: 6px 18px 6px 12px;
  color: #505a71;
  background: #f1f3f9;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  .common-selectted-icon{
    margin-left: 5px;
    i{
      position:absolute;
      top:12px;
      font-size: 8px;
    }
  }
}
.my-color-select{
  width: 118px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  padding: 5px;
}
.my-color-select-li:nth-child(-n+2){
  width: 50px;
  margin-bottom: 5px;
  cursor: pointer;
}
.my-color-select-li:not(:nth-child(-n+2)) {
  cursor: pointer;
  margin-top: 5px;
}
.my-color-select-li:nth-child(3){
  width: 50px;
  margin-top:0px;
  cursor: pointer;
}
.my-color-select-li:nth-child(4){
  width: 50px;
  margin-top:0px;
  cursor: pointer;
}
.dropdown_color{
  margin-right: 10px;
}

.input-rq{
  width: 100%;
  height: 85px;
  position: relative;
  margin-bottom: 15px;
  img{
    width: 100%;
    height: 100%;
    object-fit:cover;
    border-radius: 10px;
  }
}
.input-rq-son{
  position: absolute;
  top: calc((100% - 40px) / 2);
  left: calc((100% - 530px) / 2);
}
.clearable{
  cursor: pointer;
  margin-right: 10px;
}
.inputBox-btn{
  border-radius: 20px;
}
input:focus {
  outline: none;
}
.searchList{
  width: 530px;
  background-color: #FFFFFF;
  position: absolute;
  z-index: 999;
  margin-top: 8px;
  border-radius: 5px;
  padding-top: 5px;
  top: calc((100% + 35px) / 2);
  left: calc((100% - 530px) / 2);
  padding-bottom: 10px;
  box-shadow: 0px 0px 10px 0px rgba(33, 32, 32, 0.1);

  .searchList-content-rq{
    display: flex;
    flex-wrap: wrap;
  }
  .searchList-content{
    cursor: pointer;
    width: 50%;
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    display: flex;
    align-items: center;
    .searchList-content-num{
      margin-left: 13px;
      height: 16px;
      width: 16px;
      background: #c5c8ce;
      border-radius: 3px;
      text-align: center;
      line-height: 16px;
      font-size: 12px;
      color: #fff;
    }
    .searchList-content-lable{
      width: 100%;
      margin-left: 5px;
    }
  }
  .searchList-content:first-child .searchList-content-num{
    background: linear-gradient(1turn,#f91821,#ff6115);
  }
  .searchList-content:nth-child(2) .searchList-content-num{
    background: linear-gradient(180deg,#fdca00 -25%,#ff4501);
  }
  .searchList-content:nth-child(3) .searchList-content-num{
    background: linear-gradient(135deg,#ffe324 1.73%,#ff9c33);
  }
  .searchList-title{
    width: 100%;
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    display: flex;
    align-items: center;
    span{
      margin-left: 10px;
      color:#888;
      font-weight: bold;
    }

  }
  .history{
    display: flex;
    justify-content:space-between;
    margin-left: 10px;
    margin-right: 10px;
    .history-son-rq{
      display: flex;
      align-items: center;
      i{
        margin-right: 4px;
      }
    }
    .emptyhistory{
      cursor: pointer;
    }
    div{
      font-size: 14px;
      font-weight: bold;
      color:#888;
    }
  }
  .list{
    width: 100%;
    display: flex;
    flex-wrap:wrap;
    margin-top: 5px;
    margin-bottom: 10px;
    div:hover{
      background-color: #F0EFF4;
    }
    div{
      cursor: pointer;
      display: flex;
      background-color:  #F8F8F8;
      border-radius: 5px;
      margin-left: 10px;
      margin-top: 5px;
      padding: 5px 5px;
    }
    span{
      font-size: 12px;

    }
  }
}
</style>